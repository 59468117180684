import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: "https://kn01.pnl.gov/auth",
  realm: "WaterBalance",
  clientId: "Webui",
  keycloak_openid: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  //  redirectUri: 'http://localhost:3000',
});

export default keycloak;
