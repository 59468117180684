import React, { useState, useEffect, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Avatar,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Divider from '@material-ui/core/Divider';
import fempLogo from '../../../assets/images/FEMP_logo.png';
import HelpIcon from '@material-ui/icons/Help';
import CampusIntroduction from '../Tabs/LandingPage/CampusIntroduction';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import HomeIcon from '@material-ui/icons/Home';
import waterBalanceLogo from '../../../assets/images/water-balance-logo.png';
import SignInButton from '../../SignInButton';
import { getUserInfo, hasRole } from '../../../utilities/functions';

import styles from './styles';

import NavigationMenu from '../Administration/NavigationMenu';

const DefaultToolbar = ({ classes, children }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const user = getUserInfo();

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDialog = () => {
        setOpen(false);
    };

    const handleHelp = () => {
        setOpen(true);
    };

    const renderUserNavMenu = () => {
        // const { user } = props;
        if (user && hasRole(user, 'manage-account')) {
            return (
                <Fragment>
                    <NavigationMenu account={user} />
                </Fragment>
            );
        }
        return '';
    };

    const renderUserMenu = () => {
        const openMenu = Boolean(anchorEl);
        return (
            <div style={{ display: 'flex', width: '100%', justifyContent: "flex-end", gap: '1rem' }}>
                <IconButton
                    color="primary"
                    onClick={() => {
                        window.location.href = '/';
                    }}
                >
                    <HomeIcon />
                </IconButton>
                <IconButton color="primary" onClick={handleHelp}>
                    <HelpIcon />
                </IconButton>
                {user &&
                    <>
                        <IconButton
                            aria-owns={openMenu ? 'menu-appbar' : null}
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="primary"
                        >
                            {user.oauth_meta?.image && (
                                <Avatar
                                    alt={user.username}
                                    src={user.oauth_meta?.image}
                                />
                            )}
                            {!user.oauth_meta?.image && <AccountCircle />}
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={openMenu}
                            onClose={handleClose}
                        >
                            <MenuItem dense={true} disabled={true}>
                                {user.username || user.email}
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                dense={true}
                                button
                                component={SignInButton}
                            >
                            </MenuItem>
                        </Menu>
                    </>
                }
            </div>
        );
    }

    const renderToolbarTitle = () => {
        return (
            <Fragment>
                <img src={fempLogo} className={classes.fempLogo} alt={""} />
                <a
                    href="/"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    <img src={waterBalanceLogo} className={classes.waterBalanceLogo} alt={''} />
                </a>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Toolbar style={{ background: 'white' }}>
                {renderToolbarTitle()}
                {children}
                {renderUserNavMenu()}
                {renderUserMenu()}
            </Toolbar>
            <Dialog
                open={open}
                onClose={handleDialog}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Help
                    <CloseIcon
                        color="action"
                        onClick={handleDialog}
                        style={{ float: 'right', cursor: 'pointer' }}
                    />
                </DialogTitle>
                <DialogContent>
                    <CampusIntroduction />
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

export default withStyles(styles, { withTheme: true })(DefaultToolbar);
