import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import ApplicationLayout from "./components/Common/ApplicationLayout/ApplicationLayout";
import SignIn from "./components/SignIn";

import AccountManager from "./components/Common/Administration/AccountManager";
import LandingPage from "./components/LandingPage";
import CampusDisplay from "./components/CampusDisplay";
import keycloak from "./Keycloak";
import { checkTokenExpriry, getUserInfo, hasRole } from "./utilities/functions";

// sessionStorage.debug = 'json-rules-engine';
const App = () => {
  const [authenticated, setAuthenticated] = useState(
    sessionStorage.getItem("keycloakToken", keycloak.token)
  );

  authenticated && checkTokenExpriry();
  getUserInfo();
  // const commonProps = Object.assign({}, props, {
  //     // user: current_account,
  // });

  const user = getUserInfo();

  useEffect(() => {
    const initKeycloak = async () => {
      await keycloak.init({ onLoad: "login-required" });
      setAuthenticated(keycloak.authenticated);
      sessionStorage.setItem("keycloakToken", keycloak.token);
      sessionStorage.setItem("keycloak", JSON.stringify(keycloak));
    };
    !authenticated && initKeycloak();
  }, [authenticated]);
  return (
    <Router>
      <Switch>
        <ApplicationLayout>
          {authenticated ?
            <>
              <Route
                exact
                path="/"
                render={({ match }) =>
                  <LandingPage match={match} />
                }
              />
              <Route
                exact
                path="/secure/water-balance/campuses/:id"
                render={({ match }) =>
                  <CampusDisplay match={match} />
                }
              />
              <Route
                exact
                path="/secure/admin/accounts"
                render={({ match }) =>
                  user && hasRole(user, "manage-account") ? <AccountManager match={match} /> : <Redirect to="/" />
                }
              />
              <Route exact path="/accounts/sign_in" render={() => <SignIn />} />
            </>
            : <SignIn />}
        </ApplicationLayout>
      </Switch>
    </Router>
  );
};
export default App;
