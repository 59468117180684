export const toNumber = (value) => {
  if (value === undefined || value === null) {
    return 0;
  }
  return parseFloat(value.replace(/,/g, ""));
};

export const getBaseUrl = () => {
  // Returns the base URL (Prod/Dev) depending on the current NODE_ENV
  return process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEVELOPMENT_API_URL;
};

export const checkTokenExpriry = () => {
  if (sessionStorage && sessionStorage.getItem("keycloak")) {
    const keycloak = JSON.parse(sessionStorage.getItem("keycloak"));
    const tokenExpiry = keycloak?.idTokenParsed.exp * 1000;
    const currentTime = Date.now();
    if (currentTime > tokenExpiry || !tokenExpiry) {
      logout();
    }
  } else {
    logout();
  }
};

export const logout = () => {
  sessionStorage.removeItem("keycloakToken");
  sessionStorage.removeItem("keycloak");
  sessionStorage.removeItem("asset-score:water-balance/auth");
  window.location.reload();
};

export const getUserInfo = () => {
  if (sessionStorage && sessionStorage.getItem("keycloak")) {
    const keycloak = JSON.parse(sessionStorage.getItem("keycloak"));
    const user = {
      username: keycloak.idTokenParsed.preferred_username,
      hasEmail: keycloak.idTokenParsed.email_verified,
      roles: keycloak.resourceAccess.account.roles,
    };
    return user;
  }
};

export const hasRole = (user, role) => {
  if (user && Array.isArray(user.roles)) {
    return user.roles.filter(userRole => userRole === role).length > 0;
  }

  return false;
};
