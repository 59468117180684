import React from 'react';
import {FormSpy} from 'react-final-form';
import {FormControlLabel, Switch} from '@material-ui/core';

export const FormRulesListener = ({handleFormChange}) => (
  <FormSpy
    subscription={{values: true, valid: true}}
    onChange={async ({values, valid}) => {
      if (valid) {
        handleFormChange(values);
      }
    }}
  />
);

export const toNumber = value => {
  if (value === undefined || value === null || !value) {
    return 0;
  }
  if (typeof value === 'number') {
    return value;
  }
  return parseFloat(value.replace(/,/g, ''));
};
