import React from 'react';
import {Grid} from '@material-ui/core';
import RemoteApi from '../RemoteApi';
import CampusDialog from './Common/Tabs/LandingPage/CampusDialog';
import Campuses from './Common/Tabs/LandingPage/Campuses';
import Introduction from './Common/Tabs/LandingPage/Introduction';
import Paper from '@material-ui/core/Paper';
import DeleteCampus from './Common/Tabs/LandingPage/DeleteCampus';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      addOpen: false,
      campuses: [],
      isLoaded: false,
      isDeleteCampus: false
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleDeleteCampus = this.handleDeleteCampus.bind(this);
  }

  componentDidMount() {
    RemoteApi.getCurrentCampuses(
      campusList =>
        this.setState({
          isLoaded: true,
          campuses: campusList
        }),
      error =>
        this.setState({
          isLoaded: true,
          error
        }),
      this
    );
  }

  createNewCampus = values => {
    RemoteApi.createCampus(
      values,
      newCampus => {
        const {campuses} = this.state;
        const clone = campuses.slice();
        clone.push(newCampus);
        this.setState({
          isLoaded: true,
          addOpen: false,
          campuses: clone,
          isDeleteCampus: false
        });
      },
      error => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    );
  };

  updateCampus = values => {
    RemoteApi.updateCampus(
      values,
      () => {
        const {campuses} = this.state;
        const clone = campuses.slice();
        const updatedIndex = clone.findIndex(campus => campus.id === values.id);
        clone[updatedIndex] = values;
        this.setState({
          isLoaded: true,
          addOpen: false,
          campuses: clone,
          isDeleteCampus: false
        });
      },
      data =>
        this.setState({
          isLoaded: true,
          error: data
        })
    );
  };

  handleClose = () => {
    this.setState({addOpen: false, isDeleteCampus: false});
  };

  handleClickOpen = e => {
    let campus = undefined;
    if (e.currentTarget.id) {
      campus = this.state.campuses.find(campus => campus.id === e.currentTarget.id);
    }
    this.setState({addOpen: true, campus});
  };

  handleDeleteCampus = (campusId) => {
    let campus = undefined;
    campus = this.state.campuses.find(campus => campus.id === campusId);
    this.setState({isDeleteCampus: true, campus});
  }

  getCurrentCampuses = () => {
    RemoteApi.getCurrentCampuses(
      campusList =>
        this.setState({
          isLoaded: true,
          campuses: campusList
        }),
      error =>
        this.setState({
          isLoaded: true,
          error
        }),
      this
    )
  }

  render() {
    const {campuses, addOpen, campus, isDeleteCampus} = this.state;
    localStorage.setItem('activeTab', 0);
    return (
      <Paper style ={{width: '95%', margin: 'auto'}}>
        <Grid container>
          <CampusDialog
            createNewCampus={this.createNewCampus}
            updateCampus={this.updateCampus}
            addOpen={addOpen}
            handleClose={this.handleClose}
            campus={campus}
          />
          <Introduction />
          <Grid item xs={12}>
            <Campuses
              campuses={campuses}
              handleClickOpen={this.handleClickOpen}
              addOpen={addOpen}
              handleClose={this.handleClose}
              handleDeleteCampus={this.handleDeleteCampus}
            />
          </Grid>
          {isDeleteCampus &&
            <DeleteCampus
              isDeleteCampus={isDeleteCampus}
              handleClose={this.handleClose}
              campus={campus}
              getCurrentCampuses={this.getCurrentCampuses}
            />
          }
        </Grid>
      </Paper>
    );
  }
}

export default LandingPage;
