import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import keycloak from "../Keycloak";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import { linkStyle } from "./Common/Tabs/shared/sharedStyles";
import { logout } from "../utilities/functions";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

function SignIn(props) {
  const { classes } = props;
  const [authenticated, setAuthenticated] = useState(
    sessionStorage.getItem("keycloakToken", keycloak.token)
  );
  const [loginLogoutText, setLoginLogoutText] = useState("");

  useEffect(() => {
    setLoginLogoutText(authenticated ? "out" : "in");
  }, [authenticated]);

  return (
    <Paper className={classes.paper}>
      <Grid container justify="center" alignItems="center" spacing={16}>
        <Grid item>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar className={"avatar"}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Click or tap below to sign in with Keycloak
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
              onClick={() => (authenticated ? logout() : keycloak.login())}
            >
              Sign {loginLogoutText}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
