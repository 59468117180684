import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import {getBaseUrl} from '../../../../utilities/functions'

const DeleteCampus = (props) => {
    const { isDeleteCampus, handleClose, campus, getCurrentCampuses } = props;
    const BASE_V1_URI = getBaseUrl() || '/api';

    const handleDeleteCampus = () => {
        handleClose();
        try {
            fetch(`${BASE_V1_URI}/campuses/${campus.id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            setTimeout(() => {
                getCurrentCampuses();
            }, 250);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Dialog open={isDeleteCampus} onClose={handleClose}>
            <DialogTitle id='form-dialog-title'>
                Are you sure you want to delete this campus?
            </DialogTitle>
            <DialogActions sx={{ marginTop: ".5rem" }}>
                <Button
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteCampus}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteCampus;