import React from "react";
import { Button } from "@material-ui/core";
import { logout } from "../utilities/functions";

const SignInButton = () => {
  return (
    <Button
      type="submit"
      color="primary"
      className="submit"
      onClick={() => logout()}
    >
      Sign Out
    </Button>
  );
};

export default SignInButton;
