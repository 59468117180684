import { ca } from "date-fns/locale";
import { getBaseUrl } from "./utilities/functions";

class RemoteApi {
  static BASE_V1_URI = getBaseUrl() || "/api"; // localhost or staging depending on NODE_ENV
  static DEFAULT_HEADERS = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "https://localhost:4000",
  };
  static DEFAULT_FETCH_CONFIG = {
    method: "GET",
    credentials: "include",
    headers: {
      OwnerId: RemoteApi.retrieveOwnerId(),
      ...RemoteApi.DEFAULT_HEADERS,
    },
  };

  static captureAuthorization(response) {
    const keycloak = JSON.parse(sessionStorage.getItem("keycloak"));
    const bearer = keycloak.idToken;
    if (sessionStorage && bearer) {
      sessionStorage.setItem("asset-score:water-balance/auth", bearer);
    }
    return response.json();
  }

  static retrieveCurrentAuthorization() {
    if (sessionStorage) {
      return sessionStorage.getItem("asset-score:water-balance/auth");
    }
    return null;
  }

  static retrieveOwnerId() {
    if (sessionStorage.getItem("keycloak")) {
      const keycloak = JSON.parse(sessionStorage.getItem("keycloak"));
      const owner_id = keycloak.idTokenParsed.preferred_username;
      return owner_id;
    }
    return null;
  }

  static getCurrentCampuses = (onSuccess, onError, caller = undefined) => {
    const owner_id = RemoteApi.retrieveOwnerId();
    // fetch(`${RemoteApi.BASE_V1_URI}/campuses`, RemoteApi.DEFAULT_FETCH_CONFIG)
    fetch(`${RemoteApi.BASE_V1_URI}/campuses`, {
      ...RemoteApi.DEFAULT_FETCH_CONFIG,
      headers: {
        ...RemoteApi.DEFAULT_FETCH_CONFIG.headers,
        'owner_id': owner_id,
      },
    })
      .then((response) => RemoteApi.captureAuthorization(response))
      .then(
        // onFulfilled:
        (result) => {
          if (onSuccess) {
            onSuccess.call(caller, result);
          }
        },
        // onRejected:
        (error) => {
          if (onError) {
            onError.call(caller, error);
          }
        }
      );
  };

  static createCampus = (newCampus, onSuccess, onError, caller = undefined) => {
    const keycloak = JSON.parse(sessionStorage.getItem("keycloak"));
    const owner_id = keycloak.idTokenParsed.preferred_username;
    const fetchConfig = {
      method: "POST",
      body: JSON.stringify({
        name: newCampus.name,
        evaluator: newCampus.evaluator,
        survey: newCampus.survey,
        city: newCampus.city,
        region: newCampus.region,
        postal_code: "",
        owner_type: "",
        owner_id: owner_id,
        year: newCampus.year,
        plumbing_level: newCampus.plumbing_level,
      }),
      headers: {
        ...RemoteApi.DEFAULT_HEADERS,
      },
    };
    fetch(`${RemoteApi.BASE_V1_URI}/campuses`, fetchConfig)
      .then((response) => response.json())
      .then(
        // onFulfilled:
        (result) => {
          if (onSuccess) {
            onSuccess.call(caller, result);
          }
        },
        // onRejected:
        (error) => {
          if (onError) {
            onError.call(caller, error);
          }
        }
      );
  };

  static getCampus = (campusId, onSuccess, onError, caller = undefined) => {
    fetch(
      `${RemoteApi.BASE_V1_URI}/campuses/${campusId}`,
      RemoteApi.DEFAULT_FETCH_CONFIG
    )
      .then((response) => RemoteApi.captureAuthorization(response))
      .then(
        // onFulfilled:
        (result) => {
          if (onSuccess) {
            onSuccess.call(caller, result);
          }
        },
        // onRejected:
        (error) => {
          if (onError) {
            onError.call(caller, error);
          }
        }
      );
  };

  static updateCampus = (campus, onSuccess, onError, caller = undefined) => {
    const keycloak = JSON.parse(sessionStorage.getItem("keycloak"));
    const owner_id = keycloak.idTokenParsed.preferred_username;

    const fetchConfig = {
      method: "PUT",
      body: JSON.stringify({
        name: campus.name,
        evaluator: campus.evaluator,
        survey: campus.survey,
        city: campus.city,
        region: campus.region,
        postal_code: "",
        owner_type: "",
        owner_id: owner_id,
        year: campus.year,
        plumbing_level: campus.plumbing_level,
      }),
      headers: {
        ...RemoteApi.DEFAULT_HEADERS,
      },
    };
    fetch(
      `${RemoteApi.BASE_V1_URI}/campuses/${campus.id}`,
      Object.assign({}, RemoteApi.DEFAULT_FETCH_CONFIG, fetchConfig)
    )
      .then((response) => response.json())
      .then(
        // onFulfilled:
        (result) => {
          if (onSuccess) {
            onSuccess.call(caller, result);
          }
        },
        // onRejected:
        (error) => {
          if (onError) {
            onError.call(caller, error);
          }
        }
      );
  };
  static getCampusModules = (
    campus,
    onSuccess,
    onError,
    caller = undefined
) => {
    fetch(
        `${RemoteApi.BASE_V1_URI}/campuses/${campus.id}/modules`,
        RemoteApi.DEFAULT_FETCH_CONFIG
    )
        .then(response => RemoteApi.captureAuthorization(response))
        .then(
            // onFulfilled:
            result => {
                if (onSuccess) {
                    onSuccess.call(caller, result.campus_modules);
                }
            },
            // onRejected:
            error => {
                if (onError) {
                    onError.call(caller, error);
                }
            }
        );
};

  static getRainFall = async (zip) => {
    let result;
    result = await fetch(
      `${RemoteApi.BASE_V1_URI}/rainFalls/${zip.zip}`,
      RemoteApi.DEFAULT_FETCH_CONFIG
    );
    return result.json();
  };

  static getEto = async (zip) => {
    let result;
    result = await fetch(
      `${RemoteApi.BASE_V1_URI}/etos/${zip.zip}`,
      RemoteApi.DEFAULT_FETCH_CONFIG
    );
    return result.json();
  };

  static createOrUpdateCampusModule = (
    campus,
    campusModule,
    onSuccess,
    onError,
    caller = undefined
  ) => {
    let targetURL = null;
    let fetchConfig = {
      // method: "GET",
      body: JSON.stringify({ ...campusModule }),
      headers: {
        ...RemoteApi.DEFAULT_HEADERS,
        "Access-Control-Allow-Origin": "*",
      },
    };
    if (
      !campusModule.id 
    ) {
      fetchConfig.method = "POST";
      targetURL = `${RemoteApi.BASE_V1_URI}/campusModules/${campus.id}/modules`;
    } else {
      fetchConfig.method = "PUT";
      targetURL = `${RemoteApi.BASE_V1_URI}/campusModules/${campus.id}/modules/${campusModule.id}`;
    }
    // Perform the fetch request
  fetch(targetURL, fetchConfig)
  .then((response) => RemoteApi.captureAuthorization(response))
    .then(
      (result) => {
        if (onSuccess) {
          onSuccess.call(caller, result);
        }
      },
      (error) => {
        if (onError) {
          onError.call(caller, error);
        }
      }
    )
    .catch((error) => {
      if (onError) {
        onError.call(caller, error);
      }
    });

};

  static deleteCampusModules = (
    campus,
    campusModule,
    onSuccess,
    onError,
    caller = undefined
  ) => {
    const fetchConfig = Object.assign({}, RemoteApi.DEFAULT_FETCH_CONFIG, {
      method: "DELETE",
      headers: {
        ...RemoteApi.DEFAULT_HEADERS,
      },
    });
    fetch(
      `${RemoteApi.BASE_V1_URI}/campuses/${campus.id}/modules/${campusModule.id}`,
      fetchConfig
    )
      .then((response) => RemoteApi.captureAuthorization(response))
      .then(
        // onFulfilled:
        (result) => {
          if (onSuccess) {
            onSuccess.call(caller, result);
          }
        },
        // onRejected:
        (error) => {
          if (onError) {
            onError.call(caller, error);
          }
        }
      );
  };
}

export default RemoteApi;
