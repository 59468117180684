import React from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    withStyles
} from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        padding: 0
    },
    paper: {
        margin: 0
    },
    content: {
        padding: "0 1.5rem 0 1.5rem"
    },
    scrollPaper: {
        alignItems: 'flex-start',
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
});

const ConfirmationDialog = (props) => {
    const { classes, open, onClose, onConfirm, message } = props;
    return (
        <Dialog
            open={open}
            PaperProps={{
                className: classes.paper,
            }}
            BackdropProps={{
                className: classes.backdrop,
            }}
            classes={{
                scrollPaper: classes.scrollPaper,
            }}
        >
            <DialogContent className={classes.content}>
                <Typography variant="body1" gutterBottom>
                    {message.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} variant="contained" color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default withStyles(styles)(ConfirmationDialog);